<template>
  <div>
    <div class="card card-custom gutter-b">
      <div class="card-header border-0 py-5">
        <h3 class="card-title">
          <span class="card-label font-weight-bolder text-dark">Users</span>
        </h3>
      </div>

      <div class="card-body py-0">
        <div class="table-responsive">
          <table
            class="table table-head-custom table-vertical-center table-head-bg"
          >
            <thead>
              <tr class="text-left text-uppercase">
                <th class="px-6">Email</th>
                <th>Username</th>
                <th>Full name</th>
                <th>Phone</th>
                <th>Card</th>
                <th>Children</th>
                <th class="text-right pr-6">Options</th>
              </tr>
            </thead>
            <tr v-if="!isUsersLoaded">
              <td colspan="7" style="width: 100%; text-align: center">
                <b-spinner
                  class="mt-5"
                  variant="primary"
                  label="Spinning"
                ></b-spinner>
              </td>
            </tr>
            <tbody v-else>
              <tr v-for="(user, key) in users" :key="key">
                <td class="pl-6" :class="{ 'border-top-0': key === 0 }">
                  <router-link
                    :to="'/loyalty/users/edit/' + user.id"
                    class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
                    >{{ user.email }}</router-link
                  >
                </td>
                <td :class="{ 'border-top-0': key === 0 }">
                  <span>{{ user.username }}</span>
                </td>
                <td :class="{ 'border-top-0': key === 0 }">
                  <span>{{ user.firstName }} {{ user.surName }}</span>
                </td>
                <td :class="{ 'border-top-0': key === 0 }">
                  <span>{{ user.phone }}</span>
                </td>
                <td :class="{ 'border-top-0': key === 0 }">
                  <span v-if="user.card">{{ user.card.serialNumber }}</span>
                </td>
                <td :class="{ 'border-top-0': key === 0 }">
                  <span v-if="user.haveChildren" class="badge badge-primary">{{
                    user.children.length
                  }}</span>
                  <span v-else class="badge badge-danger">0</span>
                </td>
                <td
                  class="text-right pr-6"
                  :class="{ 'border-top-0': key === 0 }"
                >
                  <button
                    @click="toggleChangeUserCardModal(user.email)"
                    class="btn btn-sm btn-default btn-text-primary btn-hover-primary btn-icon mr-2"
                  >
                    <span class="svg-icon svg-icon-md">
                      <inline-svg
                        src="media/svg/icons/Shopping/Credit-card.svg"
                      />
                    </span>
                  </button>
                  <button
                    @click="toggleLoyaltyUsersModal(user.id)"
                    class="btn btn-sm btn-default btn-text-primary btn-hover-primary btn-icon mr-2"
                  >
                    <span class="svg-icon svg-icon-md">
                      <inline-svg src="media/svg/icons/General/User.svg" />
                    </span>
                  </button>
                  <button
                    @click.prevent="deleteEntity(user.id)"
                    class="btn btn-sm btn-default btn-text-primary btn-hover-primary btn-icon"
                  >
                    <span class="svg-icon svg-icon-md">
                      <inline-svg src="media/svg/icons/General/Trash.svg" />
                    </span>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <b-modal id="loyalty_users" size="md" hide-header hide-footer>
          <div v-if="isLoyaltyUsersLoaded">
            <b-button
              class="btn btn-light-primary mb-3"
              block
              @click="$bvModal.hide('loyalty_users')"
              >Close</b-button
            >
            <div>
              <div><strong>Username: </strong>{{ loyaltyUser.username }}</div>
              <div>
                <strong>First name: </strong>{{ loyaltyUser.firstName }}
              </div>
              <div><strong>Last name: </strong>{{ loyaltyUser.surName }}</div>
              <div><strong>Address: </strong>{{ loyaltyUser.address }}</div>
              <div><strong>Phone: </strong>{{ loyaltyUser.phone }}</div>
              <div><strong>Email: </strong>{{ loyaltyUser.email }}</div>
              <div>
                <strong>Have children: </strong>{{ loyaltyUser.haveChildren }}
              </div>
              <div
                v-for="(child, key) in loyaltyUser.children"
                :key="key"
                class="pl-2"
              >
                - <strong>Name: </strong>{{ child.name }} |
                <strong>B day: </strong>{{ child.date.date | formatDate }}
              </div>
            </div>
          </div>
        </b-modal>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <b-modal
          id="loyalty_change_user_card"
          size="md"
          hide-header
          hide-footer
        >
          <div>
            <vSelect
              v-model="selectedCard"
              class="form-control form-control-solid form-control--owner-select"
              placeholder="Select card"
              :options="cards"
              :reduce="(cards) => cards['@id']"
              label="serialNumber"
            />
            <button class="btn btn-primary btn-block mt-5" @click="updateUser">
              Update
            </button>
          </div>
        </b-modal>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import axios from 'axios'
import vSelect from 'vue-select'

export default {
  name: 'LoyaltyUsersList',
  components: {
    vSelect,
  },
  data() {
    return {
      users: [],
      rUsers: [],
      cards: [],
      currentCard: null,
      selectedCard: null,
      loyaltyUser: null,
      isUsersLoaded: false,
      isLoyaltyUsersLoaded: false,
      isCardsLoaded: false,
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Loyalty All Users', route: '' },
    ])

    this.getUsers('https://loyalty.vendingtycoon.eu/api/v1/users').then(
      ({ data }) => {
        this.users = [...data['hydra:member']]
        this.users.forEach((user, key) => {
          axios
            .get(
              `https://loyalty.vendingtycoon.eu/api/v1/cards?user.email=${user.email}`
            )
            .then(({ data }) => {
              this.users[key]['card'] = data['hydra:member'][0]
            })
        })

        setTimeout(() => {
          this.isUsersLoaded = true
        }, 1000)
      }
    )
  },
  methods: {
    async getUsers(endpoint) {
      try {
        return axios.get(endpoint)
      } catch (e) {
        throw new Error(e)
      }
    },
    toggleChangeUserCardModal(userEmail) {
      axios
        .get(`https://loyalty.vendingtycoon.eu/api/v1/cards`)
        .then(({ data }) => {
          this.cards = data['hydra:member']
          this.isCardsLoaded = true

          axios
            .get(
              `https://loyalty.vendingtycoon.eu/api/v1/cards?user.email=${userEmail}`
            )
            .then(({ data }) => {
              this.currentCard = data['hydra:member'][0]

              this.$root.$emit('bv::show::modal', 'loyalty_change_user_card')
            })
        })
    },
    toggleLoyaltyUsersModal(userId) {
      axios
        .get(`https://loyalty.vendingtycoon.eu/api/v1/users/${userId}`)
        .then(({ data }) => {
          this.loyaltyUser = null
          this.loyaltyUser = data
          this.isLoyaltyUsersLoaded = true

          this.$root.$emit('bv::show::modal', 'loyalty_users')
        })
    },
    deleteEntity() {},
    updateUser() {
      axios
        .patch(
          `https://loyalty.vendingtycoon.eu${this.selectedCard}`,
          {
            saldo: this.currentCard.saldo,
            isActive: true,
            isVerified: true,
            totalSpent: this.currentCard.totalSpent,
            user: this.currentCard.user,
          },
          {
            headers: {
              'Content-Type': 'application/merge-patch+json',
            },
          }
        )
        .then(() => {
          axios
            .patch(
              `https://loyalty.vendingtycoon.eu${this.currentCard['@id']}`,
              {
                user: null,
              },
              {
                headers: {
                  'Content-Type': 'application/merge-patch+json',
                },
              }
            )
            .then(() => {
              this.$root.$emit('bv::hide::modal', 'loyalty_change_user_card')

              this.getUsers(
                'https://loyalty.vendingtycoon.eu/api/v1/users'
              ).then(({ data }) => {
                this.isUsersLoaded = false
                this.users = []
                this.users = [...data['hydra:member']]
                this.users.forEach((user, key) => {
                  axios
                    .get(
                      `https://loyalty.vendingtycoon.eu/api/v1/cards?user.email=${user.email}`
                    )
                    .then(({ data }) => {
                      this.users[key]['card'] = data['hydra:member'][0]
                    })
                })

                setTimeout(() => {
                  this.isUsersLoaded = true
                }, 1000)
              })
            })
        })
    },
  },
}
</script>

<style lang="scss">
@import 'vue-select/src/scss/vue-select.scss';

.form-control {
  &--owner-select {
    height: unset !important;

    .vs__dropdown-toggle {
      padding: 0;
      border: 0;
      border-radius: 0;
    }
    .vs__dropdown-menu {
      border: 1px solid #e4e6ef;
      -webkit-box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);
      box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);

      /* width */
      &::-webkit-scrollbar {
        width: 4px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #888;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }

      li {
        padding: 0.65rem 1rem;

        &:hover {
          background: #f3f6f9;
          color: #3f4254;
        }
      }
    }

    .vs__open-indicator {
    }
    .vs__dropdown-toggle {
    }
    .vs__clear {
      display: none;
    }
    .vs__selected {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      padding: 0.25rem 0.75rem;
      font-size: 1rem;
      margin: 0.1rem 0.4rem 0.1rem 0;
      position: relative;
      float: left;
      color: #3f4254;
      background: #f3f6f9;
      border: 0;
      border-radius: 0.42rem;
    }
    .vs__no-options {
    }
    .vs__spinner {
    }
    .vs__deselect {
    }
    .vs__active {
    }
    .vs__dropdown-option--highlight {
      background: #ebedf3;
      color: #3f4254;
    }
  }
}
</style>
